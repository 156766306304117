import SmoothScroll from 'smooth-scroll';

import AOS from 'aos';

const drawerAnchor = document.querySelectorAll('#navigation a');

drawer_open.addEventListener('click', e => {
    navigation.classList.add('open');
})

drawer_close.addEventListener('click', e => {
    navigation.classList.remove('open');
})

drawerAnchor.forEach(item => {
    item.addEventListener('click', e => {
        navigation.classList.remove('open');
    })
});

const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 300,
    offset: function (anchor, navigation) {
        if(screen.width <= 640) {
            return 48;
        }else {
            return 72;
        }
    },
    easing: 'easeInOutQuint',
    updateURL: false,
    popstate: false
});

// アニメーション設定
AOS.init({
    delay: 200,
    offset: 200,
    once: true,
    duration: 800,
});
